<template>
  <!-- Contamination artificielle -->
  <form @submit.prevent="onSubmit()">
    <div class="artificial-contamination-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Contamination artificielle</h2>
        </Container>
      </div>

      <Section>
        <Container>
          <Input id="produitcontaminant" label="Nom du produit contaminant" />
        </Container>
      </Section>
      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: {id: $route.params.id} }" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import Btn from '@/components/base/Btn.vue'
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ArtificialContaminationSubview',
  components: {
    Container,
    Section,
    Input,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      produitcontaminant: this.yup.string().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      fiche: {},

      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
    }
  },

  mounted() {
    this.getFiche()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getFiche() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/contamination`)
        .then((response) => {
          const data = response.data[0]
          this.fiche = data

          const schema = this.formService.populateShema(this.fiche)

          this.formService.setFormValues(schema)

          this.emitter.emit('close-loader')
        })
    },

    handleSubmit(values) {
      this.emitter.emit('open-loader')

      this.fetchService
        .put(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/contamination/${this.fiche.id}`, values)
        .then((response) => {
          this.fiche = response.data
          this.taskService.handleExperimentalTaskRedirect(this.$route)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
